<script setup lang="ts">
const { data: pageContent } = await useAsyncData('content:components/connect', () => queryContent('components/connect').findOne())
const { data: sharedContent } = await useAsyncData('content:shared', () => queryContent('shared').findOne())

</script>

<template>
  <ULandingSection
    v-if="pageContent"
    class="relative overflow-hidden z-10"
    :icon="pageContent.icon"
    :title="pageContent.title"
    :description="pageContent.description"
    align="left"
    :ui="{
      icon: {
        wrapper: 'flex mb-6',
        base: 'size-24'
      },
    }"
  >
    <template #top>
      <DotPatternBackground class="-z-10"/>
    </template>

    <template #description>
      <p class="mb-6 text-xl">{{ pageContent.description }}</p>
      <div class="flex flex-wrap gap-8">
        <UButton v-bind="pageContent.cta"/>
        <UButton variant="link" :to="`mailto:${sharedContent?.emails.contact}`">{{ sharedContent?.emails.contact }}</UButton>
      </div>
    </template>

    <template #default>
      <div class="flex flex-col gap-2 w-fit h-full justify-end">
        <UButton
          v-for="link in [sharedContent?.phone, ...sharedContent?.socialLinks]"
          :key="link.label"
          :to="link.to"
          :label="link.label"
          :aria-label="link.label"
          :icon="link.icon"
          :external="link.external"
          :target="link.target ?? link.external ? '_blank' : undefined"
          variant="link"
        />
      </div>
    </template>
  </ULandingSection>
</template>
