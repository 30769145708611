<script setup lang="ts">
const props = defineProps({
  class: {
    default: undefined,
    type: [ String, Object, Array ] as PropType<object | string | undefined>,
  },
  direction: {
    default: 'left',
    type: String as PropType<'flat' | 'left' | 'right'>,
  },
})
</script>

<template>
  <div
    class="absolute origin-center w-[140%] h-[140%] top-[-20%] left-[-20%] opacity-30 bg-gradient-to-b from-transparent from-30% via-primary-600 dark:via-primary-400 to-70% [mask-image:url('/images/shared/dot-pattern.png')]"
    :class="[props.direction === 'left' ? '-rotate-12 xl:-rotate-6' : props.direction === 'right' ? 'rotate-12 xl:rotate-6' : '', props.class]"
  />
</template>
